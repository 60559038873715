import React from 'react';
import style from './Preloader.module.scss';

const Preloader = () => {
  return (
      <div className={style.container}>
          <preloader className={style.preloader}>
          <span className={style.abc}>&lt;</span>
            <span className={style.letter}>C</span>
            <span className={style.letter}>O</span>
            <span className={style.letter}>D</span>
            <span className={style.letter}>E</span>
            <span className={style.letter}>R</span>
            <span className={style.abc}>&gt;</span>
          </preloader>
      </div>
  )

};

export default Preloader;
