import React, { useState } from 'react';
import style from './HomePage.module.scss';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import NavBar from '../../shared/NavBar/NavBar'
import RightSideImage from '../../img/undraw_content_team_3epn.svg'

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ECommerceImage from '../../img/Services/Services-01.png';
import AppDevelopmentImage from '../../img/Services/Services-02.png';
import UiUxImage from '../../img/Services/Services-03.png';
import WebDevelopmentIMage from '../../img/Services/Services-04.png';

import RightArrow1 from '../../img/icons/chevron-right.svg';
import Sitemap from '../../shared/Sitemap/Sitemap';


const HomePage = () => {

    const [click, setClick] = useState(false);

    const handleClick = () => {
        setClick(!click);
        console.log('THis ');
    };

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 3000,
        cssEase: "linear"
    };

    return (
        <div>

            <div id='home' className={style.container}>

                <div className={style.navBar}>
                    <NavBar
                        click={click}
                        // handleChange={handleChange}
                        handleClick={handleClick}
                    ></NavBar>
                </div>
                <div className={classNames(style.contentsWrapper, {
                    [style.noContentsWrapper]: click === true,
                })}>
                    <div className={style.contents}>
                        <div className={style.left}>
                            <div className={classNames(style.tagLine, 'animate__animated animate__fadeInLeft')}>
                                Change The Game With Innovation <span className={style.face}> ツ </span>
                            </div>
                            <a href="#about">
                                <div style={{ width: "9.7vw" }} className={classNames(style.btnContainer, 'animate__animated animate__fadeIn')}>
                                    <button className={style.learnMore}>
                                        <span className={classNames(style.circle, style.smallCircle)} aria-hidden="true">
                                            <img className={style.firstArrow} src={RightArrow1} alt=">" />
                                            {/* <img className={style.secondArrow} src={RightArrow2} alt="-->" /> */}
                                        </span>
                                        <span className={style.buttonText}> What? </span>
                                    </button>
                                </div>
                            </a>
                        </div>
                        <div className={style.right}>
                            <img className='animate__animated animate__fadeInRight' src={RightSideImage} alt="" />
                        </div>
                    </div>
                    <section id='about' className={style.aboutSection}>
                        <div className={style.about}>
                            <div className={style.aboutImage}>
                                <img src="https://images.pexels.com/photos/1170412/pexels-photo-1170412.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" alt="" />
                            </div>
                            <div className={style.aboutSectionText}>
                                <div className={style.description}>
                                    We are a software company that truly care
                                    about our customers and goes above and beyond to provide an undiscovered innovative idea for our customers' needs.
                                </div>
                                <div className={classNames(style.btnContainer, 'animate__animated animate__fadeInLeft')}>
                                    <Link to="/about" style={{ textDecoration: 'none' }}>
                                        <button className={style.whatWeDo}>
                                            <span className={style.circle} aria-hidden="true">
                                                <img className={style.firstArrow} src={RightArrow1} alt=">" />
                                                {/* <img className={style.secondArrow} src={RightArrow2} alt="-->" /> */}
                                            </span>
                                            <span className={style.buttonText}> What we do?</span>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className={style.technologies}>
                            <Slider {...settings}>
                                <div>
                                    <h3>&lt; html /&gt;</h3>
                                </div>
                                <div>
                                    <h3>&lt; css /&gt;</h3>
                                </div>
                                <div>
                                    <h3>&lt; JavaScript /&gt;</h3>
                                </div>
                                <div>
                                    <h3>&lt; ReactJs /&gt;</h3>
                                </div>
                                <div>
                                    <h3>&lt; NodeJs /&gt;</h3>
                                </div>
                                <div>
                                    <h3>&lt; MongoDb /&gt;</h3>
                                </div>
                            </Slider>
                        </div>
                    </section>
                    <section className={style.servicesSection}>
                        <h1 className={style.header}>
                            Services
                        </h1>
                        <div className={style.services}>
                            <div className={style.card}>
                                <div className={style.image}>
                                    <img src={WebDevelopmentIMage} alt="" />
                                </div>
                                <div className={style.title}>
                                    Web<br />  Development
                                </div>
                                <div className={style.cardDescription}>
                                    A website can help businesses increase flexibility, control, branding and credibility. Having a website attracts new customers and establish your place in your industry.
                                </div>
                            </div>
                            <div className={style.card}>
                                <div className={style.image}>
                                    <img src={ECommerceImage} alt="" />
                                </div>
                                <div className={style.title}>
                                    E-commerce <br /> Solutions
                                </div>
                                <div className={style.cardDescription}>
                                    Online shopping is booming. Be a part of complex and always evolving world of eCommerce. Conduct
                                    your business electronically.
                                </div>
                            </div>
                            <div className={style.card}>
                                <div className={style.image}>
                                    <img src={UiUxImage} alt="" />
                                </div>
                                <div className={style.title}>
                                    UI/UX
                                    <br /> Design
                                </div>
                                <div className={style.cardDescription}>
                                    To create user-friendly interfaces that enable users to understand how to use complex technical products.
                                </div>
                            </div>
                            <div className={style.card}>
                                <div className={style.image}>
                                    <img src={AppDevelopmentImage} alt="" />
                                </div>
                                <div className={style.title}>
                                    Mobile<br />  Applications
                                </div>
                                <div className={style.cardDescription}>
                                    Mobile apps make customer service a simpler and more efficient process for businesses. it gives customer an ability to directly interact with the company.
                                </div>
                            </div>
                        </div>
                    </section>
                    <Sitemap />
                </div>
            </div>
        </div>
    )
}

export default HomePage
