import React from 'react';
import { Link } from 'react-router-dom';
import style from './Contact.module.scss'

//icons
import FacebookIcon from '../../img/icons/facebook.svg'
import InstagramIcon from '../../img/icons/instagram.svg'
import LinkedinIcon from '../../img/icons/linkedin.svg'
import backBtn from '../../img/icons/chevron-left.svg';

const contact = () => {
  return (
    <div className={style.container}>
      <Link to="/">
        <div className={style.backButton}>
          <img src={backBtn} alt="" />
        </div>
      </Link>
      <div className={style.pageContent}>
        <div className={style.pageHeader}>
          CONTACT
        </div>
        <div className={style.pageDescription}>
          If you have any questions about new projects please contacts us. we will get back to you as soon as possible.
        </div>
        <div className={style.socialMediaIcons}>
          <a href="https://www.instagram.com/redocdev" rel="noreferrer" target="_blank">
            <div className={style.instagramIcon}>
              <img src={InstagramIcon} alt="" />
            </div>
          </a>
          <a href='https://www.facebook.com/RedocDev' rel="noreferrer" target="_blank">
            <div className={style.facebookIcon}>
              <img src={FacebookIcon} alt="" />
            </div>
          </a>
          <a href="https://www.linkedin.com/company/redocdev" rel="noreferrer" target="_blank">
            <div className={style.linkedinIcon}>
              <img src={LinkedinIcon} alt="" />
            </div>
          </a>
        </div>
        <div className={style.emailAddress}>
          hello@redocdev.com
        </div>
      </div>
    </div>
  )
};

export default contact;
