// import style from './App.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useState, useEffect } from 'react';
import HomePage from './pages/HomePage/HomePage';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import Preloader from './shared/Preloader/Preloader';
import Career from './pages/Career/Career';

function App() {

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false)
    }, 3500)
  }, [])

  return (
    <BrowserRouter>
      {
        loading ?
          <Preloader/>
          :
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route exact path="/" element={<HomePage />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/career" element={<Career />} />
            <Route exact path="/contact" element={<Contact />} />
          </Routes>
      }
    </BrowserRouter>
  );
}

export default App;
