import React from 'react';
import style from './NavBar.module.scss';
import { Link } from 'react-router-dom';
import 'animate.css';

//images
import RedocLogo from '../../img/Logo.jpg';
import Menu from '../../img/icons/menu.svg'

import classNames from 'classnames';

const NavBar = ({ click, handleClick }) => {

    return (
        <div className={style.container}>
            <div onClick={() => handleClick()} className={style.menuIcon}>
                <img src={Menu} alt="" />
            </div>
            {
                click &&
                <nav className={classNames(style.navBar1, 'animate__animated animate__fadeInRight')}>
                    {/* <nav className={style.navBar1}> */}
                    <div className={style.pageLinks}>
                        {/* <Link to="/" style={{ textDecoration: 'none' }}>
                            <div className={style.navLink}>
                                Home
                            </div>
                        </Link> */}
                        <Link to="/about" style={{ textDecoration: 'none' }}>
                            <div className={style.navLink}>
                                About
                            </div>
                        </Link>
                        {/* <div className={style.navLink}>
                            Works
                        </div> */}
                        <Link to="/career" style={{ textDecoration: 'none' }}>
                            <div className={style.navLink}>
                                Careers
                            </div>
                        </Link>
                        <Link to="/contact" style={{ textDecoration: 'none' }}>
                            <div className={style.navLink}>
                                Contact
                            </div>
                        </Link>

                    </div>
                </nav>
            }
            <nav className={classNames(style.navBar2, 'animate__animated animate__fadeInDown')}>
                <Link to={"/"}>
                <div className={style.logo}>
                    <img src={RedocLogo} alt="" />
                </div>
                </Link>
                <div className={style.pageLinks}>
                    <Link to="/" style={{ textDecoration: 'none' }}>
                        <div className={style.navLink}>
                            Home
                        </div>
                    </Link>
                    <Link to="/about" style={{ textDecoration: 'none' }}>
                        <div className={style.navLink}>
                            About
                        </div>
                    </Link>
                    {/* <div className={style.navLink}>
                        Works
                    </div> */}
                    <Link to="/career" style={{ textDecoration: 'none' }}>
                        <div className={style.navLink}>
                            Careers
                        </div>
                    </Link>
                    <Link to="/contact" style={{ textDecoration: 'none' }}>
                        <div className={style.navLink}>
                            Contact
                        </div>
                    </Link>

                </div>
            </nav>
        </div>
    )
}

export default NavBar
