import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import style from './About.module.scss';
import classNames from 'classnames';

// Icons
import FacebookIcon from '../../img/icons/facebook.svg';
import InstagramIcon from '../../img/icons/instagram.svg';
import LinkedinIcon from '../../img/icons/linkedin.svg';
import backBtn from '../../img/icons/chevron-left.svg';


const About = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <div className={style.container}>
            <div className={style.leftSide}>
                <Link to="/">
                    <div className={style.backButton}>
                        <img src={backBtn} alt="" />
                    </div>
                </Link>
                <div className={style.pageHeader}>
                    <div className={style.title}>
                        <span classname={style.about}>
                            About </span> <br /> Redoc
                    </div>
                    <div className={style.tagline}>
                        You change the game with innovation
                    </div>
                    <div className={style.socialMediaIcons}>
                        <a href="https://www.instagram.com/redocdev" rel="noreferrer" target="_blank">
                            <div className={style.instagramIcon}>
                                <img src={InstagramIcon} alt="instagram" />
                            </div>
                        </a>
                        <a href='https://www.facebook.com/RedocDev' rel="noreferrer" target="_blank">
                            <div className={style.facebookIcon}>
                                <img src={FacebookIcon} alt="facebook" />
                            </div>
                        </a>
                        <a href="https://www.linkedin.com/company/redocdev" rel="noreferrer" target="_blank">
                            <div className={style.linkedinIcon}>
                                <img src={LinkedinIcon} alt="linkedin" />
                            </div>
                        </a>
                    </div>
                    <div className={style.companyEmail}>
                        redocdevelopers@gmail.com
                    </div>
                </div>
            </div>
            <div className={style.rightSide}>
                <div className={style.pageContent}>
                    <div className={style.whoAreWe}>
                        <div className={style.subHeader}>
                            Who Are We?
                        </div>
                        <div className={style.text}>
                            We are Redoc Developers who's aim is your satisfaction.
                        </div>
                    </div>
                    <div className={style.crew}>
                        <div className={style.subHeader}>
                            Our Crew
                        </div>
                        <div className={style.text}>
                            In the present day our crew consist of four geeky friends who loves gaming, movies, animes and what not. Name anything geeky we probably love it ;) <br />
                            Three of whom are graduates of the University of Colombo School of Computing and one who is an entrepreneurial self-learner with out of the box creative thinking
                        </div>
                    </div>
                    <div className={style.companyDetails}>
                        <div className={style.companyDetailsHeader}>
                            Company Details
                        </div>
                        <div className={style.companyDetailsContent}>
                            <div className={classNames(style.subHeader, style.details)}>
                                Name
                            </div>
                            <div className={style.text}>
                                Redoc Developers
                            </div>
                            <div className={classNames(style.subHeader, style.details)}>
                                Address
                            </div>
                            <div className={style.text}>
                                141 Isipathana Rd, <br /> Colombo 5
                            </div>
                            <div className={classNames(style.subHeader, style.details)}>
                                Email
                            </div>
                            <div className={style.text}>
                                redocdev@gmail.com
                            </div>
                            <div className={classNames(style.subHeader, style.details)}>
                                Founded By
                            </div>
                            <div className={style.text}>
                                Kasun Shanaka <br /> Nishan Sathsara <br /> Shakya Karunathilake  <br />Shanika Weerasinghe
                            </div>
                            <div className={classNames(style.subHeader, style.details)}>
                                Number of employees
                            </div>
                            <div className={style.text}>
                                4
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
