import React from 'react';
import style from './Sitemap.module.scss'

//icons
import RedocLogo from '../../img/Logo-Transparent.png';
import FacebookIcon from '../../img/icons/facebook.svg'
import InstagramIcon from '../../img/icons/instagram.svg'
import LinkedinIcon from '../../img/icons/linkedin.svg'
import { Link } from 'react-router-dom';

const Sitemap = () => {
    return (
        <div className={style.container}>
            <section className={style.footer}>
                <div className={style.contactDetails}>
                    <div className={style.logo}>
                        <img src={RedocLogo} alt="" />
                    </div>
                    <div className={style.phoneNumber}>
                        077 1232343
                    </div>
                    <div className={style.email}>
                        hello@redocdev.com
                    </div>
                    <div className={style.address}>
                        141 Isipathana Rd, <br />
                        Colombo 5
                    </div>
                    <div className={style.socialMedia}>
                        <a href="https://www.instagram.com/redocdev" rel="noreferrer" target="_blank">
                            <div className={style.instergramLogo}>
                                <img src={InstagramIcon} alt="" />
                            </div>
                        </a>
                        <a href='https://www.facebook.com/RedocDev' rel="noreferrer" target="_blank">
                            <div className={style.facebookLogo}>
                                <img src={FacebookIcon} alt="" />
                            </div>
                        </a>
                        <a href="https://www.linkedin.com/company/redocdev" rel="noreferrer" target="_blank">
                            <div className={style.linkedInLogo}>
                                <img src={LinkedinIcon} alt="" />
                            </div>
                        </a>
                    </div>
                </div>

                <div className={style.location}>

                </div>
                <div className={style.siteMap}>
                    <div className={style.title}>
                        Sitemap
                    </div>
                    <a href="#home">
                        <div className={style.home}>
                            home
                        </div>
                    </a>
                    <Link to={"contact"}>
                        <div className={style.contact}>
                            Contact
                        </div>
                    </Link>
                    <Link to={"/about"}>
                        <div className={style.about}>
                            About
                        </div>
                    </Link>
                    {/* <div className={style.projects}>
                            Projects
                        </div> */}
                </div>
            </section>
            <footer className={style.copyright}>
                © 2021 Redoc Developers. All Rights Reserved.
            </footer>
        </div>
    )
};

export default Sitemap;
